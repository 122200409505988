<template>
	<div class="content">
		<vue-headful
			:title="pageTitle"
		/>
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		<!-- <Load v-if="isLoading"/> -->
		
		<side-menu v-if="!isLoading"
		           v-show="showMenu"
		           :id-museum="encrypted"
		           @close-menu="showMenu = false"/>
		
		<nav v-if="!isLoading">
			<router-link :to="{ name: 'Presentation', params: { idMuseum: encrypted, mode: 'by-halls' } }" class="logo">
				<img class="logo-image" :src="museum.topBarLogoImageUrl ? museum.topBarLogoImageUrl : museum.logoImageUrl" alt="">
			</router-link>
			<router-link :to="{ name: 'Languages', params: { idMuseum: encrypted } }" class="menu negative">
				<i class="mm-icon mm-icon-flag menu-icon"/>
			</router-link>
			<div v-if="museum.menuAvailable" class="menu negative" @click="showMenu = true; setFullScreen()">
				<i class="mm-icon mm-icon-info-simple menu-icon"/>
			</div>
			<!-- <router-link v-if="!museum.menuAvailable && sideMenu && sideMenu.downloadsTopBar" :to="{ name: 'Downloads', params: { idMuseum: encrypted } }" class="menu">
				<i class="mm-icon mm-icon-download menu-icon"/>
			</router-link> -->
		</nav>
		
		<main v-if="!isLoading && timeline" id="timeline" ref="timeline" class="timeline">
			<div v-for="(step, index) in timeline.steps" :key="step.idStep" :class="{ 'last-is-instructions': ((index === timeline.steps.length - 1) && !step.idHall) }" class="step">
				<section v-if="step.idHall" :id="step.idStep" class="hall" @click="goToHall(step)">
					<div class="floor-number" :style="'opacity: ' + (hasToShowFloor(step, index) ? '1' : '0' )">
						<span class="floor-number-text">{{ $i18n.t('floor') }}</span>
						<span class="floor-number-number">{{ getHall(step.idHall).locale.floor }}</span>
					</div>
					<div v-if="getHall(step.idHall).locale" class="hall-info">
						<p v-if="getHall(step.idHall).locale.number" class="hall-number">{{ $i18n.t('hall').toUpperCase() }} {{ getHall(step.idHall).locale.number }}</p>
						<p class="hall-name">{{ getHall(step.idHall).locale.name }}</p>
						<p class="hall-extra-info">{{ getHall(step.idHall).locale.extraInfo }}</p>
					</div>
				</section>
				
				<section v-else class="instruction">
					<div v-if="step.locale.mainAudioUrl || !step.locale.instructions"
					     :id="step.idStep"
					     class="instruction-button"
					     :class="{ 'blink': step.locale.mainAudioUrl && instructionsAudioToShow !== index }"
					     @click="toggleInstructionsAudioToShow(index)">
						<i v-if="step.iconType && step.iconType !== 'custom'" class="mm-icon" :class="'mm-icon-' + step.iconType"/>
						<img v-if="step.iconType && step.iconType === 'custom'" class="mm-icon" :src="step.iconUrl" alt="">
					</div>
					
					<div v-else-if="step.locale.instructions" class="instruction-text">
						{{ step.locale.instructions }}
					</div>
					
					<div v-if="instructionsAudioToShow === index" class="audio-player-container">
						<audio-player v-if="step.locale.mainAudioUrl" class="audio-player"
						              :file="step.locale.mainAudioUrl"/>
					</div>
				</section>
			</div>
		</main>
		
		<div class="up-arrow-container">
			<div class="up-arrow">
				<i class="mm-icon mm-icon-arrow-back menu-icon" @click="scrollToTop()"/>
			</div>
		</div>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import {setFullScreen} from "@/store/helpers";
import SideMenu from "@/components/SideMenu";
import AudioPlayer from "@/components/AudioPlayer";
// import Load from '../../../components/Load.vue';

export default {
	name: "ByHallsOptions",
	
	components: {
		// Load,
		Loading,
		SideMenu,
		AudioPlayer
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			showMenu: false,
			sideMenuOptions: [
				'start',
				'inAMinute',
				'outstandingArtworks',
				'language',
				'exploreMuseum',
				'guide',
				'study',
				'downloads',
			],
			instructionsAudioToShow: null
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			timeline: state => state.timeline.timeline,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('start');
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate By Halls";
			
			return locationName + ' | ' + museumName;
		},
		
		halls() {
			return this.timeline.halls;
		},
		
		sideMenu () {
			return this.museum.sideMenuCustomization;
		},
	},
	
	created() {
		debug.open("By Halls - Options");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, { name: 'By Halls - Options', params: { idMuseum: this.encrypted } });
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.finally(async () => {
					await this.getTimeline();
					debug.close();
					await this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
					
					//Scrolls to the last visited hall
					if(this.$route?.query?.hall) {
						let queryIdHall = parseInt(this.$route?.query?.hall);
						let hall = this.timeline.steps.find(step => step.idHall === queryIdHall);
						if(hall) {
							let stepsMap = this.timeline.steps.map(step => step.idStep);
							let hallIndex = stepsMap.indexOf(hall.idStep);
							let previousIdStep = stepsMap[hallIndex - 1] || stepsMap[0];
							document.getElementById(previousIdStep).scrollIntoView({
								behavior: "smooth"
							});
						}
					}
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			debug.close();
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('timeline', ['getTimeline']),
		
		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},
		
		goToHall(hall) {
			setFullScreen();
			this.$router.push({ name: 'By Halls - Hall', params: { idMuseum: this.encrypted, idHall: hall?.idHall } });
		},
		
		hasToShowFloor(step, index) {
			let currentFloor = this.getHall(step.idHall)?.locale?.floor;
			
			//If it is a hall with a floor
			if(step.idHall && currentFloor) {
				//And is not the first one (always show on the first one)
				if(index > 0) {
					//Check if it is the same as the previous one
					let previousHall = this.timeline.steps[index - 1].idHall
						? this.getHall(this.timeline.steps[index - 1].idHall)
						: null;
					
					if(previousHall) {
						return previousHall.locale?.floor !== currentFloor;
					}
				}
				
				return true;
			}
			return false;
		},
		
		getHall(idHall) {
			return this.halls?.find(hall => hall.idHall === idHall);
		},
		
		goToSideMenuOption(to) {
			setFullScreen();
			let viewName = 'By Halls - Options';
			switch(to) {
				case 'start':
					viewName = 'By Halls - Options';
					break;
				case 'inAMinute':
					viewName = 'OneMinuteList';
					break;
				case 'outstandingArtworks':
					viewName = 'OutstandingArtworks';
					break;
				case 'language':
					viewName = 'Languages';
					break;
				case 'exploreMuseum':
					viewName = 'InfoSectionList';
					break;
				case 'guide':
					viewName = 'GuidedVisit';
					break;
				case 'study':
					viewName = 'StudyQuestion';
					break;
				case 'downloads':
					viewName = 'Downloads';
					break;
			}
			this.$router.push({ name: viewName, params: {idMuseum: this.encrypted} });
		},
		
		scrollToTop() {
			this.$refs.timeline.scrollIntoView({
				behavior: "smooth"
			});
		},
		
		toggleInstructionsAudioToShow(index) {
			if(this.timeline?.steps[index]?.locale?.mainAudioUrl) {
				this.instructionsAudioToShow = (this.instructionsAudioToShow === index)
					? null
					: index;
			}
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../../../theme/colors.scss';

body {
	background-color: $by-halls-dark-gray;
}

.content {
	width: 100%;
	min-height: 100vh;
	background-color: $by-halls-dark-gray;
	overflow-y: auto;
	
	nav {
		position: fixed;
		top: 0;
		z-index: 100;
		height: 75px;
		min-height: 75px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $by-halls-dark-gray;
		padding: 0 30px;
		
		.logo {
			padding: 5px 0;
			height: 100%;
			display: flex;
			align-items: center;
			
			.logo-image {
				max-height: 100%;
				max-width: 100%;
			}
		}
		
		.menu {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 5px 20px;
			width: 80px;
			text-decoration: none;
			
			&:not(:last-child) {
				margin-right: 10px;
				margin-left: auto;
			}
			
			.menu-icon {
				color: $by-halls-primary;
				text-decoration: none;
				font-size: 40px;
				width: 50px;
				margin-bottom: -4px;
				height: auto;
				
				&.big {
					font-size: 50px;
					margin-bottom: -15px;
				}
			}
			
			&.negative {
				background-color: $by-halls-primary;
				border-radius: 50%;
				height: 50px;
				width: 50px;
				
				.menu-icon {
					color: $by-halls-dark-gray;
				}
			}
		}
	}
	
	.timeline {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		overflow-y: auto;
		padding: 120px 20px 80px;
		
		.step {
			width: 100%;
			margin-bottom: 10px;
			position: relative;
			
			&:after {
				content: '';
				width: 4px;
				position: absolute;
				height: 120%;
				top: 0;
				left: 48px;
				background-color: $by-halls-primary;
			}
			
			&:first-child:after {
				top: 50%;
			}
			
			&:last-child:after {
				height: 50%;
			}
			
			&.last-is-instructions:after {
				height: 50%;
			}
			
			.hall-floor {
				text-align: right;
				font-size: 14px;
				text-transform: uppercase;
				padding: 5px 10px;
				width: 100%;
				background-color: $by-halls-light-gray;
				color: $by-halls-primary;
			}
			
			.hall {
				width: 100%;
				height: 125px;
				background-color: $by-halls-gray;
				color: white;
				display: flex;
				align-items: center;
				padding: 10px;
				
				.floor-number {
					background-color: $by-halls-primary;
					color: $by-halls-gray;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					min-height: 80px;
					height: 80px;
					min-width: 80px;
					width: 80px;
					z-index: 10;
					
					.floor-number-text {
						text-transform: uppercase;
						margin-bottom: 0;
						font-size: 14px;
						margin-top: 10px;
						font-weight: 600;
					}
					
					.floor-number-number {
						margin-top: -10px;
						margin-bottom: -10px;
						font-size: 48px;
						font-weight: 900;
					}
				}
				
				.hall-info {
					margin-left: auto;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					align-items: flex-end;
					text-align: right;
					
					.hall-number {
						font-weight: 300;
						color: $by-halls-primary;
					}
					
					.hall-name {
						font-size: 24px;
						font-weight: bold;
						color: $by-halls-primary;
					}
				}
			}
			
			.instruction {
				z-index: 20;
				position: relative;
				display: flex;
				align-items: center;
				
				.instruction-button {
					min-height: 50px;
					min-width: 50px;
					border-radius: 50%;
					background-color: black;
					color: $by-halls-primary;
					
					margin: 20px 0 20px 25px;
					
					display: flex;
					justify-content: center;
					align-items: center;
					
					.mm-icon {
						font-size: 28px;
						margin-bottom: -5px;
						
						max-width: 40px;
						max-height: 40px;
					}
					
					&.blink {
						animation: blink 0.75s ease alternate infinite;
						//box-shadow: 0 0 10px white, 0 0 12px rgba($by-halls-primary, 50%);
						
						@keyframes blink {
							from { box-shadow: 0 0 10px white, 0 0 12px rgba($by-halls-primary, 50%); }
							to { box-shadow: none; }
						}
					}
				}
				
				.instruction-text {
					padding: 5px 0 ;
					line-height: 1.5;
					background-color: $by-halls-dark-gray;
					color: white;
					
					margin: 20px 0;
					font-size: 24px;
					overflow: hidden;
					
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
				
				.audio-player-container {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
	
	.up-arrow-container {
		cursor: pointer;
		z-index: 100;
		position: fixed;
		bottom: 0;
		text-align: center;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		
		.up-arrow {
			height: 50px;
			width: 50px;
			border: 4px solid white;
			border-radius: 50%;
			transform: rotate(90deg);
			
			.mm-icon {
				font-size: 32px;
				color: white;
				
				&:before {
					margin-left: 12px;
					margin-top: 7px;
				}
			}
		}
	}
}
</style>
